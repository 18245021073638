import React, { useRef } from 'react'
import { Table } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';


const ModulationReportPrint = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const componentPDF = useRef();
  
    const { customerDetails , searchValue } = location.state;

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    function backtodata() {
        navigate("/app/modulation_report");
    }

    ///////////////////////////////////////////////////////////////
  return (
    <>



<div className='container d-flex justify-content-center align-items-center text-dark' ref={componentPDF} style={{ width: "100%" }}>
                <div className='row '>

                  
                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com</p>
                
              </div>





                    <div className='text-center mt-2'><h5><b>Modulation Report:</b></h5></div>




                    <div className='d-flex justify-content-center '>

                    <Table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">Sl No.</th>
                                    <th scope="col">Cust Name</th>
                                    <th scope="col">Cust Id</th>
                                    <th scope="col">Mod Quo No</th>
                                    <th scope="col">Quo Amt</th>
                                    <th scope="col">Pre Bal</th>
                                    <th scope="col">Paid Amt</th>
                                    
                                    <th scope="col">Total Bal</th>
                                    <th scope="col">Status</th>
                                  

                                </tr>
                            </thead>
                            <tbody>
                            {customerDetails
                                .filter(item =>
                                    item.mod_unique_id.includes(searchValue) ||
                                    item.mod_cu_name.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.mod_working_status.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.mod_cu_name}</td>
                                        <td>{item.mod_unique_id}</td>
                                        <td>{item.mod_final_quo_no}</td>
                                        <td>{item.mod_final_total_amt}</td>
                                        <td>{item.mod_pre_bal}</td>
                                        <td>{item.mod_paid_amt}</td>
                                        <td>{item.mod_total_bal}</td>
                                        <td>{item.mod_working_status}</td>
                                      

                                    </tr>
                                ))}

                            </tbody>
                        </Table>

                    </div>


                </div>
            </div>

         

            <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                <button type="button" className="btn btn-primary mx-2 ms-5" onClick={backtodata}>Cancel</button>
                <button type="button" onClick={generatePDF} style={{ width: "80px" }} className="btn btn-primary mx-3">Ok</button>

            </div>

    </>
  )
}

export default ModulationReportPrint