import React, { useRef , useEffect} from 'react'
import { Table } from "react-bootstrap"
import { useReactToPrint } from 'react-to-print';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const OtherPaymentViewPrint = () => {

    let { id } = useParams();
    const navigate = useNavigate();
    const componentPDF = useRef();
    const location = useLocation();


    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    function backtodata() {
        navigate("/app/other_paid_data");
    }

    // const paymentData = location.state.paymentData;
    // const other_pay_id = location.state.other_pay_unique_id;
    let paymentData = location.state ? location.state.paymentData : [];

  useEffect(() => {
    console.log('Payment data:', paymentData);
  }, [paymentData]);


    return (
        <>

            <div className='container d-flex justify-content-center align-items-center text-dark ' ref={componentPDF} style={{ width: "100%" }}>
                <div className='row  '>

                <div className=' d-flex  justify-content-between  '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2  ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com</p>
                
              </div>





                    <div className='text-center mt-1'><h5><b>Other Payment View:</b></h5></div>




                    <div className='d-flex justify-content-center '>

                        <Table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">Sl No.</th>
                                    <th scope="col">Cust Id</th>
                                    <th scope="col">Customer name</th>
                                    <th scope="col">Receive Payment Date</th>
                                    <th scope="col">Mobile No</th>
                                    <th scope="col">Paym</th>
                                    <th scope="col">Quotation Amt</th>
                                    <th scope="col">Pending Amt</th>
                                    <th scope="col">Recently Paid Amt</th>
                                    <th scope="col">Total bal</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {paymentData.map((item, index) => (
                                    <tr key={item.other_pay_id}>
                                        <td>{index + 1}</td>
                                        <td>{item.other_pay_unique_id}</td>
                                        <td>{item.other_pay_cu_name}</td>
                                        <td>{item.other_pay_date}</td>
                                        <td>{item.other_pay_mobile_no}</td>
                                        <td>{item.other_payment_mode}</td>
                                        <td>{item.other_pay_quo_amt}</td>
                                        <td>{item.other_pay_pre_bal}</td>
                                        <td>{item.other_pay_paid_amt}</td>
                                        <td>{item.other_pay_total_bal}</td>
                                        <td>{item.other_pay_work_status}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>

                    </div>


                </div>
            </div>

            <div className='mt-4 float-end'>
                <button type="button" className="btn btn-primary mx-2 ms-5" onClick={backtodata}>Cancel</button>
                <button className='btn btn-primary' onClick={generatePDF} style={{ width: "100px" }}>Ok</button><br /><br /><br /><br />

            </div>

        </>
    )
}

export default OtherPaymentViewPrint