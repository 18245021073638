import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useLocation ,useParams} from 'react-router-dom';


const ModulationQuotationViewPrint = () => {


  const navigate = useNavigate();
  const componentPDF = useRef();

  const location = useLocation();
  const { dataId } = useParams();

  const itemData = location.state?.data[0];

  console.log("itemData123",itemData);



  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Userdata",

  });


  /////////////////////////////////////////////////

  const previousPage = () => {
    navigate("/app/modulation_data")
  }



  return (
    <>
      {/* <div>
      {itemData && (
        <div>
          <h2>Customer Name: {itemData.mod_quo_cu_name}</h2>
          <p>Customer Address: {itemData.mod_quo_cu_address}</p>
         
        </div>
      )}
    </div> */}


      <div className='container-fluid d-flex justify-content-center align-items-center ' ref={componentPDF} style={{color: "black"}}>
        <div className='row  '>

        <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com</p>
                
              </div>

          <div className='d-flex justify-content-end mb-3'>
            <div className='me-4'>Job Id:{itemData?.mod_cu_unique_id}<br />
              Quotation No:{itemData?.mod_quo_cu_quono}</div>
          </div>
      
          <div className='d-flex justify-content-between'>

            <div className=''>

              <div className='ms-5'>To</div>

              <div><h6 className='ms-5'><b></b></h6>
                <h6  className='ms-5'><b>{itemData.mod_quo_cu_name}</b></h6></div>
            </div>

            <div className='me-5'>
              <div><h6>Date:{itemData?.mod_quo_date}</h6>
                <h4></h4></div>
            </div>

          </div>

          <div className='text-center mt-3'><p> <b>Thank you for your kind enquiry and pleased to quote for the following items </b></p></div>

          <div className='d-flex justify-content-center '>

            <table class="table table-bordered" style={{ width: "90%", border: "1px solid black" }}>
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Sl No.</th>
                  <th style={{ width: "45%" }}>DISCRIPTION</th>
                  <th style={{ width: "15%" }}>Qty</th>
                  <th style={{ width: "15%" }}>Rate</th>
                  <th style={{ width: "15%" }}>Amount</th>
                </tr>
              </thead>

              <tbody>

              {itemData &&
                    itemData.modu_perticulars &&
                    itemData.modu_perticulars.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.particular}</td>
                        <td>{item.quantity}</td>
                        <td>{item.unite_rate}</td>
                        <td>{item.total_amt}</td>
                      </tr>
                    ))}
                  <tr>

                
                  <td></td>
                  <td><strong>Terms & Conditions </strong><br />
                  1: 80% advance payment <br />
                  2: {itemData?.mod_quo_term_con} <br /></td>
                  <td></td>
                  <td style={{ borderTop: "none" }}><h5>Nett Rs..</h5></td>
                 <td>{itemData?.mod_quo_total_amt}</td>
                </tr>
                
              </tbody>
            </table>

          </div>

          <div className='d-flex justify-content-between'>

          <div className='ms-5'>
            <div>
              GST
            </div>
            <div>
              Sales and Service Office
            </div>
            <div>
              Email ID
            </div>
          </div>

          <div><h5><b>For Je-N Elevators</b></h5></div>
          

          </div>    


        </div>
      </div>

      <div className='mt-4 float-end mb-5'>
        <button type="button" onClick={previousPage} className="btn btn-primary mx-2 ms-5 ">Cancel</button>
        <button className='btn btn-primary ' onClick={generatePDF}>print</button>
      </div>
    </>
  )
}

export default ModulationQuotationViewPrint