import React, { useRef , useState } from 'react'
import { Table } from "react-bootstrap"
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const AmcExpiryDataPrint = () => {

    const componentPDF = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchValue, setSearchValue] = useState('');

    const { customerDetails } = location.state;

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    const openPrint = () => {
       
        navigate("/app/amc_expiry");
    }

    const filterData = (searchValue) => {
        return customerDetails.filter((item) => {
          const lowerCaseSearch = searchValue.toLowerCase();
          return (
            item.amc_quo_name.toLowerCase().includes(lowerCaseSearch) ||
            item.amc_quo_mob_no.toString().includes(lowerCaseSearch) ||
            item.amc_working_status.toLowerCase().includes(lowerCaseSearch)
    
            
            
            // Add other fields for search if required
            // ...
            // For other radioSelected values, add the relevant fields for filtering
          );
        });
      };


    return (
        <>


            <div className='container d-flex justify-content-center align-items-center text-dark ' ref={componentPDF} style={{ width: "100%" }}>
                <div className='row '>


                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com</p>
                
              </div>






                    <div className='text-center mt-2'><h5><b>AMC Expiry:</b></h5></div>




                    <div className='d-flex justify-content-center '>

                        <Table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">Sl No.</th>
                                    <th scope="col">Customer name</th>
                                    <th scope="col">Job Id</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Total Bal</th>
                                    <th scope="col">Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                 {/* {customerDetails.map((item, index) => ( */}
                                 {filterData(searchValue).map((item, index) => {
                                     if (item.amc_working_status === 'Expired') {
                                        return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.amc_quo_name}</td>
                                        <td>{item.amc_fl_cu_unique_id}</td>
                                        <td>{item.amc_quo_start_date}</td>
                                        <td>{item.amc_quo_end_date}</td>
                                        <td>{item.amc_quo_total_bal}</td>
                                        <td>{item.amc_working_status}</td>

                                    </tr>

                                        );
                                     }
                                     return null; // Skip rendering this row if it doesn't match the criteria
                                    }
                                )
                                
                            }

                            </tbody>
                        </Table>

                    </div>


                </div>
            </div>


            <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                <button type="button" className="btn btn-primary mx-2 ms-5" onClick={openPrint}>Cancel</button>
                <button type="button" onClick={generatePDF} style={{ width: "80px" }} className="btn btn-primary mx-3">Ok</button>

            </div>


        </>
    )
}

export default AmcExpiryDataPrint