import React, { useRef } from 'react'
import {Table} from "react-bootstrap"
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const AmcReportPrint = () => {


    const componentPDF = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const { customerDetails , searchValue } = location.state;

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });


    function backtodata() {
        navigate("/app/amc_report");
    }

  return (
    <>
    
    <div className='container d-flex justify-content-center align-items-center text-dark' ref={componentPDF} style={{ width: "100%" }}>
                <div className='row  '>

                      
                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com</p>
                
              </div>

                  

                    <div className='text-center mt-2'><h5><b>AMC Report:</b></h5></div>




                    <div className='d-flex justify-content-center '>

                        <Table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">Sl No.</th>
                                    <th scope="col">Cust Name</th>
                                    <th scope="col">Job Id</th>
                                    <th scope="col">Amc Quo No</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Pre Bal</th>
                                    <th scope="col">Paid Amt</th>
                                    <th scope="col">Bal</th>
                                    <th scope="col">Status</th>

                                </tr>
                            </thead>
                            <tbody>
                            {customerDetails
                                .filter(item =>
                                    item.amc_fl_cu_unique_id.includes(searchValue) ||
                                    item.amc_quo_name.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.amc_working_status.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.amc_quo_name}</td>
                                        <td>{item.amc_fl_cu_unique_id}</td>
                                        <td>{item.amc_quo_final_quo_no}</td>
                                        <td>{item.amc_quo_start_date}</td>
                                        <td>{item.amc_quo_end_date}</td>
                                        <td>{item.amc_quo_pre_bal}</td>
                                        <td>{item.amc_quo_paid_amt}</td>
                                        <td>{item.amc_quo_total_bal}</td>
                                        <td>{item.amc_working_status}</td>

                                    </tr>
                                ))}

                            </tbody>
                        </Table>

                    </div>


                </div>
            </div>

            <div className='mt-4 float-end'>

            <button type="button" className="btn btn-primary mx-2 ms-5" onClick={backtodata}>Cancel</button>
            <button className='btn btn-primary' onClick={generatePDF} style={{width:"100px"}}>Ok</button><br /><br /><br /><br />

            </div>
    
    
    </>
  )
}

export default AmcReportPrint