import React, { useRef } from 'react'
import { Table } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';


const EnquiryReportPrint = () => {

    const history = useNavigate()

    const componentPDF = useRef();
    const navigate = useNavigate();
    // const location = useLocation();

    // const { customerDetails , searchValue } = location.state;

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    ///////////////////////////////////////////////////////////////


  return (
    <>

    
<div className='container d-flex justify-content-center align-items-center text-dark' ref={componentPDF} style={{ width: "100%" }}>
                <div className='row '>

                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com</p>
                
              </div>




                    <div className='text-center mt-1'><h5><b>Enquiry Report:</b></h5></div>




                    <div className='d-flex justify-content-center '>

                    <Table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col">Date</th>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Mobile No</th>
                                <th scope="col">Lift Type</th>
                                <th scope="col">Lift Travel</th>
                                <th scope="col">Address</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        {/* <tbody>
                            {customerDetails
                                .filter(item =>
                                    item.en_mob_no.includes(searchValue) ||
                                    item.en_cu_name.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.en_date}</td>
                                        <td>{item.en_cu_name}</td>
                                        <td>{item.en_mob_no}</td>
                                        <td>{item.en_lift_type}</td>
                                        <td>{item.en_lift_travel}</td>
                                        <td>{item.en_cu_address}</td>
                                        <td>{item.en_status}</td>
                                    </tr>
                                ))}
                        </tbody> */}
                    </Table>

                    </div>


                </div>
            </div>

         

            <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                <button type="button" onClick={()=>history(-1)}  className="btn btn-primary mx-2 ms-5 ">Cancel</button>
                <button type="button" onClick={generatePDF} style={{ width: "80px" }} className="btn btn-primary mx-3">Ok</button>

            </div>
    </>
  )
}

export default EnquiryReportPrint