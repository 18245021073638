import React, { useRef } from 'react'
import { Table } from "react-bootstrap"
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";




const ModulationPaymentPrint = () => {

    const navigate = useNavigate();
    const componentPDF = useRef();
    const location = useLocation();

    const { data } = location.state;



    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    function backtodata() {
        navigate("/app/modulation_paid_data");
    }

    //////////////////////////////////////////////////

    return (
        <>


            <div className='container d-flex justify-content-center align-items-center ' ref={componentPDF} style={{ width: "100%" }}>
                <div className='row '>

                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com</p>
                
              </div>

                    
                    <div className='text-center text-dark mt-2'><h5><b>Modulation Received Payment:</b></h5></div>

                    <div className='d-flex justify-content-center '>

                        <Table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">Sl No.</th>
                                    <th scope="col">Receive Payment Date</th>
                                    <th scope="col">Customer name</th>
                                    <th scope="col">Cust Id</th>
                                    <th scope="col">Paym</th>
                                    <th scope="col">Previous Bal</th>
                                    <th scope="col">Paid Amt</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item) =>
                                        <tr key={item.mod_pay_id}>
                                             <td>{item.mod_pay_id}</td>
                                            <td>{item.mod_pay_date}</td>
                                            <td>{item.mod_pay_cu_name}</td>
                                            <td>{item.mod_pay_unique_id}</td>
                                            <td>{item.mod_payment_mode}</td>
                                            <td>{item.mod_pay_pre_bal}</td>                                          
                                            <td>{item.mod_pay_paid_amt}</td>
                                            <td>{item.mod_pay_total_bal}</td>
                                            <td>{item.mod_pay_work_status}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>

            <div className='mt-4 float-end'>
                <button type="button" className="btn btn-primary mx-2 ms-5" onClick={backtodata}>Cancel</button>
                <button className='btn btn-primary' onClick={generatePDF} style={{ width: "100px" }}>Ok</button><br /><br /><br /><br />

            </div>


        </>
    )
}

export default ModulationPaymentPrint