import React, { useRef } from 'react'
import { Table } from "react-bootstrap"
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';


const AmcQuatationPrint = () => {

    const componentPDF = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    const { customerName, quoDate, quotationNo, uniqueId, mobileNo, preAddress,
        liftTravel, stops, amcStartDate, amcEndDate, quantity,doorType,
        quotationAmt } = location.state;

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });


    //////////////////////////////////////////////////////////////////////////


    const previousPage = () => {
        navigate("/app/amc_data")
    }




    return (
        <>
            <div className='container-fluid d-flex justify-content-center align-items-center  ' ref={componentPDF} style={{ width: "100%" , color: "black" }}>
                <div className='row'>

                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>



                    <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com</p>
                
              </div>



                    <div className='d-flex justify-content-between align-items-center '>
                    <div className='  ms-3' style={{ color: "black" }} ><h6>{quoDate}</h6></div>
                        <div className=' me-1' style={{ color: "black" }} >Job Id:{uniqueId} <br />
                            Quotation No:{quotationNo}</div>

                    </div>


                    <div>
                        <h6 className='ms-5 mt-1'>To,</h6>
                        <h6 className='ms-5' style={{ color: "black" }}><b>{customerName}</b> </h6>
                        <h6 className='ms-5' style={{ color: "black" }}><b>{preAddress}</b></h6>
                        {/* <h6 className='ms-5'>Place: </h6> */}
                    </div>

                    <div className='mt-1 ms-5'><b>Dear sir,</b></div>
                    <div className='d-flex justify-content-center'><p style={{ width: "87%", fontSize: "14px" }}>We propose to furnish our maintainance Service for the following Elevators. <br />
                        <b> Types of Elevator {doorType},</b> </p></div>

                    <div className='d-flex justify-content-center '><p className='' style={{ width: "87%", fontSize: "14px" ,textAlign:"justify"}}>The service will consists of regular examination of the Elevator including lubrication and cleaning of the machine,Controller, greasing and lubricating of car and counter  weight guide rails and making service once in two months of your Elevator and to provide breakdown service on calls. <br />
                        We Thank you very much for your valuable enquiry.Base on your requirment we are hereby submitting our competitive for your kind approval </p></div>
                    <div className='ms-5'><h5><b>COMMERCIAL OFFER:</b></h5></div>
                   <div className='d-flex justify-content-center '>

                        <table className="table table-bordered m-0" style={{ width: "90%", border: "1px solid black" }}>
                            <tbody>

                                <tr>
                                    <td style={{ width: "10%" }}>SL No.</td>
                                    <td style={{ width: "40%" }}>Perticulars</td>
                                    <td style={{ width: "15%" }}> Start Date</td>
                                    <td style={{ width: "15%" }}>End Date</td>
                                    <td style={{ width: "10%" }}>Qty</td>
                                    <td style={{ width: "10%" }}>Amount</td>
                                </tr>
                                <tr>

                                    <td>1</td>
                                    <td style={{ color: "black" }}>(Non Compressive) Annual Maitainance Contract</td>
                                    <td style={{ color: "black" }}>{amcStartDate}</td>
                                    <td style={{ color: "black" }}>{amcEndDate}</td>
                                    <td style={{ color: "black" }}>{quantity}</td>
                                    <td style={{ color: "black" }}>{quotationAmt}</td>

                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div className=' d-flex'>

                        <div className='ms-5'><h><b>Notes:</b></h></div>
                        <div className=' '>
                            <ol>
                                <li style={{ fontSize: "14px" }}>Invoice for payment of the contract price will be issued and rare payable in advance.</li>
                                <li style={{ fontSize: "14px" }}>The contract price is based on the present market rates of raw material cost and cost of labor an if there be any altercation of these cost the contract price will vary accordingly.</li>

                            </ol>
                        </div>

                    </div>


                    <h6 className='ms-5'><b><u>Terms and condition:-</u></b></h6>

                    <div >
                        <ol className='ms-5'>
                            <li style={{ fontSize: "14px" }}> This Service Shall commence from {amcStartDate} and shall continue therafter until terminated. Either party may terminated this agreement either at the end of first year or at the end of subsequent year by giving a (30) days notice in writing.</li>
                            <li style={{ fontSize: "14px" }}>Performing of neccesary minor adjustment. This contract doesn't cover replacement of almost all worm-out parts and components of the equipment.</li>
                            <li style={{ fontSize: "14px" }}>Reporting the customer will report to us any malfunction or dangerous working condition of the electronic and will keep the electronic out of the service till such time the defect is rectified by us.</li>
                            <li style={{ fontSize: "14px" }}>The agency will render emergency call back service during regular and non-regular working Hours.</li>
                            <li style={{ fontSize: "14px" }}>The cost of replacement of lift components and equipment will be bearded by the Department and labor charges will be given free of cost by the agency.</li>
                        </ol>
                    </div>
                    <div className='  ms-5 mt-2 '><h6 className=''>Thanks and Regards.</h6></div>
                    <div className='ms-5 mb-2'>
                        <div><h6>Je-N Elevators</h6></div>
                    </div>

                    
                </div>
            </div>

            <div className='mt-4 float-end'>
                <button type="button" onClick={previousPage} className="btn btn-primary mx-2 ms-5 ">Cancel</button>
                <button className='btn btn-primary' onClick={generatePDF} style={{ width: "100px" }}>Ok</button><br /><br /><br /><br />

            </div>
        </>
    )
}

export default AmcQuatationPrint