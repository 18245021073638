import * as Yup from "yup";

export const liftPaymentSchema = Yup.object({

//  customerName:  Yup.string().required("Please select customer name"),
 payDate:  Yup.string().required("Please select date"),
 paymentMode:  Yup.string().required("Please enter payment mode"),
 paidAmt: Yup.string().required("Please enter pay amount"), 
 liftPayStatus:Yup.string().required("Please select  status"),
//  followUpDate:  Yup.string().required("Please select follow up date"),
 receivedBy:  Yup.string().required("Please enter received by"),
//  remarks:  Yup.string().required("Please enter remarks"),
// uniqueId: Yup.string().required("Please enter site id"),

}); 