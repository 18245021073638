import React, { useRef } from 'react'
import { Table } from "react-bootstrap"
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const CustomerReportPrint = () => {

    const componentPDF = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const { customerDetails , searchValue } = location.state;

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    const backtodata = () => {
        navigate("/app/customer_report")
    }


    return (
        <>


            <div className='container d-flex justify-content-center align-items-center text-dark ' ref={componentPDF} style={{ width: "100%" }}>
                <div className='row  '>

                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              <div></div>
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 7090910934. jnelevators@gmail.com/www.jen.elevator.com</p>
                
              </div>

                    <div className='text-center'><h5><b>Customer Report:</b></h5></div>

                    <div className='d-flex justify-content-center '>

                        <Table className="table table-bordered mt-5">
                            <thead>
                                <tr>
                                    <th scope="col">Sl No</th>
                                    <th scope="col">Job Id</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Mobile No.</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Type of Enquiry</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerDetails
                                    .filter(item => {
                                        const workStatus = item.cu_lift_type ? item.cu_lift_type.toLowerCase() : '';
                                        const customerName = item.cu_name ? item.cu_name.toLowerCase() : '';
                                        const customerId = item.cu_unique_id ? item.cu_unique_id : '';
                      
                                        return (
                                          workStatus.includes(searchValue.toLowerCase()) ||
                                          customerName.includes(searchValue.toLowerCase()) ||
                                          customerId.includes(searchValue)
                      
                                        );
                                      })
                                    .map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.cu_unique_id}</td>
                                            <td>{item.cu_date}</td>
                                            <td>{item.cu_name}</td>
                                            <td>{item.cu_mobile}</td>
                                            <td>{item.cu_address}</td>
                                            <td>{item.cu_lift_type}</td>
                                        </tr>
                                    ))}
                            </tbody>

                        </Table>


                    </div>


                </div>
            </div>

            <div className='mt-4 float-end'>

                <button type="button" className="btn btn-primary mx-2 ms-5" onClick={backtodata} >Cancel</button>
                <button className='btn btn-primary' onClick={generatePDF} style={{ width: "100px" }}>Ok</button><br /><br /><br /><br />

            </div>

        </>
    )
}

export default CustomerReportPrint